import { CircularProgress } from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import JSONData from "../../../components/JSONData";
import { useQuerySnapshotPreview } from "../../../hooks/UseQuerySnapshotPreview";

interface SnapshotDataPreviewProps {
  snapshotId: string;
  textFieldStyle?: React.CSSProperties;
}

const SnapshotDataPreview = ({
  snapshotId,
  textFieldStyle,
}: SnapshotDataPreviewProps) => {
  const qPreview = useQuerySnapshotPreview(snapshotId);

  if (qPreview.isLoading) {
    return <CircularProgress />;
  }
  if (qPreview.isError) {
    return <ErrorAlert value={qPreview.error} />;
  }
  if (!qPreview.isSuccess) {
    return (
      <ErrorAlert
        value={`Unexpected error for snapshot ${snapshotId} preview`}
      />
    );
  }

  return (
    <JSONData
      JSONObject={qPreview.data.json_data_preview}
      textFieldStyle={textFieldStyle}
    />
  );
};

export default SnapshotDataPreview;
