import API from "../../API";
import { AIBakedCode } from "../../types/AIBakedCode";
import { CodeRunReview } from "../../types/CodeRunReview";
import { DataLabelsDetailed } from "../../types/DataLabelsDetailed";
import { GPTModel } from "../../types/GPTModel";
import { GPTPrompts } from "../../types/GPTPrompt";
import { SchemaCustomization } from "../../types/SchemaCustomization";
import {
  SnapshotMinusTemplatePacked,
  SnapshotMinusTemplateUnpacked,
} from "../../types/SnapshotMinusTemplate";
import { TemplateFork } from "../../types/TemplateFork";
import { TemplatingImpactSummary } from "../../types/TemplatingImpactSummary";
import { ValidationBlameInstance } from "../../types/ValidationBlameInstance";
import { axiosTokened } from "../../utils/AxiosInstances";

export const getCodeReview = async (
  challengeId: string,
  codeRunNumber: string,
  userId: string
) => {
  const { data } = await axiosTokened.get<CodeRunReview>(
    API.genGetCodeRunReview(challengeId, codeRunNumber, userId)
  );
  return data;
};

export const postCodeReview = async (
  challengeId: string,
  codeRunNumber: string,
  userId: string,
  rejectionText?: string
) => {
  await axiosTokened.post(
    API.genPostCodeRunReview(challengeId, codeRunNumber, userId),
    { rejection_text: rejectionText }
  );
};

// FIXME: remove hardocding
export const getSnapshotStatistics = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<any>(
    API.genGetSnapshotStatistics(snapshotId)
  );
  return data;
};

export const getSnapshotTemplatingPacked = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<SnapshotMinusTemplatePacked>(
    API.genGetPackedTemplating(snapshotId)
  );
  return data;
};

export const getSnapshotTemplatingUnpacked = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<SnapshotMinusTemplateUnpacked>(
    API.genGetUnpackedTemplating(snapshotId)
  );
  return data;
};

export const getSchemaCustomization = async (snapshotId: string) => {
  const { data } = await axiosTokened.get<SchemaCustomization>(
    API.genGetSchemaCustomizationBySnapId(snapshotId)
  );
  return data;
};

export const postTemplateSaveAs = async (
  templateId: string,
  data: TemplateFork
) => {
  await axiosTokened.post(API.genPostTemplateSaveAs(templateId), data);
};

export const postTemplateSave = async (
  templateId: string,
  data: { data_labels: DataLabelsDetailed }
) => {
  await axiosTokened.post(API.genPostTemplateSave(templateId), data);
};

export const postEvaluateTemplatingImpact = async (
  snapshotId: string,
  labels: DataLabelsDetailed
) => {
  const { data } = await axiosTokened.post<TemplatingImpactSummary>(
    API.genPostEvaluateTemplatingImpact(snapshotId),
    labels
  );
  return data;
};

export interface CodePromptGenerateProps {
  templateId: string;
  challengeId: string;
  userEmail?: string;
  proxyType: string;
  codeUserId?: string;
  codeRunId?: string;
  validationId?: string;
}

export interface CodeGenerateProps extends CodePromptGenerateProps {
  model: string;
  prompts: GPTPrompts;
}

const _generateGPTParams = (
  props: CodePromptGenerateProps | CodeGenerateProps
) => {
  let base: any = {
    template_id: props.templateId,
    challenge_id: props.challengeId,
    user_email: props.userEmail || "", // not needed. Tracker like
    proxy: props.proxyType,
    code_user_id: props.codeUserId,
    code_run_id: props.codeRunId,
    validation_id: props.validationId,
  };
  if ("model" in props) {
    base["model"] = props.model;
  }
  return base;
};

export const postGenerateCode = async (props: CodeGenerateProps) => {
  const body =
    props.prompts.length > 0 ? { prompts: props.prompts } : undefined;
  const { data } = await axiosTokened.post<AIBakedCode>(
    API.postGenerateCode,
    body,
    {
      params: _generateGPTParams(props),
    }
  );
  return data;
};

export const getGPTModels = async () => {
  const { data } = await axiosTokened.get<GPTModel[]>(API.getGPTModels);
  return data;
};

export const getDefaultGPTCodePrompt = async (
  props: CodePromptGenerateProps
) => {
  const { data } = await axiosTokened.get<GPTPrompts>(API.getGPTCodePrompt, {
    params: _generateGPTParams(props),
  });
  return data;
};

export const getValidationBlame = async (
  snapshotId: string,
  validationId: string
) => {
  const { data } = await axiosTokened.get<ValidationBlameInstance[]>(
    API.genGetSnapshotValidationBlame(snapshotId, validationId)
  );
  return data;
};
