import React, { useState } from "react";
import {
  DialogProps,
  Button,
  Snackbar,
  Alert,
  Box,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TabbedContentDialog from "./TabbedContentDialog";
import SnapshotDataPreview from "../../SnapshotsViewSteps/components/SnapshotDataPreview";
import { useQuerySnapshotHead } from "../../../hooks/UseQuerySnapshotHead";
import ExternalLink from "../../../components/ExternalLink";
import SnapshotStatistics from "./SnapshotStatistics";

interface SnapshotPreviewDialogProps extends DialogProps {
  snapshotId: string;
  templateId: string;
  title?: string;
  text?: string;
  onClose: () => void;
  isDownloadShown?: boolean;
  contentStyle?: React.CSSProperties;
}

const SnapshotPreviewDialog = (props: SnapshotPreviewDialogProps) => {
  const {
    snapshotId,
    templateId,
    onClose,
    open,
    title = "Snapshot preview",
    text,
    isDownloadShown,
    contentStyle,
    maxWidth = "md",
    ...dialogProps
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const qSnapshotHead = useQuerySnapshotHead(snapshotId);
  const snapshotUrl = qSnapshotHead.data?.json_data_file_url;

  const copySnapshotURLToClipboard = () => {
    if (snapshotUrl) {
      navigator.clipboard.writeText(snapshotUrl).then(
        () => {
          setSnackbarMessage("Link copied!");
          setSnackbarOpen(true);
        },
        () => {
          setSnackbarMessage("Failed to copy link.");
          setSnackbarOpen(true);
        }
      );
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const downloadLink = isDownloadShown && !!snapshotUrl && (
    <Box>
      <Button
        onClick={copySnapshotURLToClipboard}
        startIcon={<ContentCopyIcon />}
      >
        Copy Link
      </Button>
      <ExternalLink href={snapshotUrl}>
        <Button startIcon={<FileDownloadIcon />}>Download</Button>
      </ExternalLink>
    </Box>
  );

  const _entitiesCount = qSnapshotHead.data?.entities_count ?? -1;
  const entitiesCount = _entitiesCount >= 0 ? _entitiesCount : "Not Applicable";

  return (
    <>
      <TabbedContentDialog
        open={open}
        onClose={onClose}
        prefix={text ?? "Providing the details about the snapshot."}
        maxWidth={maxWidth}
        tabs={[
          {
            title: "Snapshot",
            content: (
              <>
                <Typography>Entities count: {entitiesCount}</Typography>
                <SnapshotDataPreview
                  snapshotId={snapshotId}
                  textFieldStyle={{ height: "68vh" }}
                />
              </>
            ),
          },
          {
            title: "Statistics",
            content: (
              <SnapshotStatistics
                templateId={templateId}
                snapshotId={snapshotId}
              />
            ),
          },
        ]}
        title={title}
        contentStyle={{ minWidth: "45vw", height: "85vh", ...contentStyle }}
        downloadLink={downloadLink}
        {...dialogProps}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnapshotPreviewDialog;
