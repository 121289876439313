import { useMemo } from "react";
import TitledCodeEditorCard from "./TitledCodeEditorCard";

export interface JSONDataProps {
  JSONObject?: any;
  textFieldStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

export const objectToPrettyJSON = (JSONObject: any) => {
  try {
    return JSON.stringify(JSONObject, null, 2);
  } catch (ex) {
    return "{}";
  }
};

const JSONData = ({
  JSONObject,
  textFieldStyle,
  wrapperStyle,
}: JSONDataProps) => {
  const JSONPrettyString = useMemo<string>(
    () => objectToPrettyJSON(JSONObject),
    [JSONObject]
  );

  return (
    <TitledCodeEditorCard
      language="json"
      value={JSONPrettyString}
      wordWrap
      style={textFieldStyle}
      wrapperStyle={wrapperStyle}
    />
  );
};

export default JSONData;
