import React from "react";
import { Box, Typography } from "@mui/material";
import { DiffEditor } from "@monaco-editor/react";
import { Snapshot } from "../../../types/SnapshotHead";
import { Anomaly } from "../../../types/Anomaly";
import TTabID from "../../../types/ChallengeViewTabsEnum";
import { Link } from "react-router-dom";
import { useSortedSnapshotBefore } from "../../SnapshotsViewSteps/helpers/snapshotHelpers";

interface SnapshotComparisonProps {
  snapshotData: Snapshot;
  penultimateSnapshotData: Snapshot;
  anomaly: Anomaly;
}

const SnapshotComparison: React.FC<SnapshotComparisonProps> = ({
  snapshotData,
  penultimateSnapshotData,
  anomaly,
}) => {

  const sortedPenultimateSnapshot = useSortedSnapshotBefore(penultimateSnapshotData.json_data);
  const sortedSnapshot = useSortedSnapshotBefore(snapshotData.json_data)

  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={1}>
      {snapshotData && penultimateSnapshotData && (
        <Box marginTop={1}>
          <Box display={"flex"} marginBottom={2}>
            <Box
              display={"flex"}
              width={"48%"}
              justifyContent={"space-between"}
              marginRight={2}
            >
              <Typography variant="h6" fontWeight={"medium"}>
                Before: {penultimateSnapshotData.entities_count} entities
              </Typography>
              <Typography
                variant="h6"
                component={Link}
                reloadDocument
                to={`/challenges/${anomaly.challenge_id}/?tab=${TTabID.SubmitCode}&runNumber=${anomaly.penultimate_snapshot_code_run_id}&userId=${anomaly.penultimate_snapshot_user_id}`}
              >
                [Show more]
              </Typography>
            </Box>
            <Box
              display={"flex"}
              width={"48%"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6" fontWeight={"medium"}>
                After: {snapshotData.entities_count} entities
              </Typography>
              <Typography
                variant="h6"
                component={Link}
                reloadDocument
                to={`/challenges/${anomaly.challenge_id}/?tab=${TTabID.SubmitCode}&runNumber=${anomaly.snapshot_code_run_id}&userId=${anomaly.snapshot_user_id}`}
              >
                [Show more]
              </Typography>
            </Box>
          </Box>
          <DiffEditor
            original={sortedPenultimateSnapshot}
            modified={sortedSnapshot}
            language="json"
            height="40vh"
            options={{ readOnly: true, enableSplitViewResizing: false }}
          />
        </Box>
      )}
    </Box>
  );
};

export default SnapshotComparison;
