import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { BackendError } from "../types/BackendError";
import { getSnapshotPreview } from "../pages/SnapshotsViewSteps/LocalOperations";
import { SnapshotPreview } from "../types/SnapshotPreview";

export const useQuerySnapshotPreview = (
  snapshotId: string | number,
  options?: Omit<
    UseQueryOptions<SnapshotPreview, BackendError, SnapshotPreview, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _snapshotId = String(snapshotId);
  return useQuery<SnapshotPreview, BackendError>(
    ["snapshotPreview", _snapshotId],
    () => getSnapshotPreview(_snapshotId),
    options
  );
};
