import TitledCodeTextCard from "../../../components/TitledCodeTextCard";
import TabbedContentDialog, { DialogTabs } from "./TabbedContentDialog";
import { Validation } from "../../../types/Validations";
import { DialogProps, Typography } from "@mui/material";
import { getStatusClassname } from "../../../components/ColoredStatus";
import { useQuery } from "react-query";
import { ValidationBlameInstance } from "../../../types/ValidationBlameInstance";
import { BackendError } from "../../../types/BackendError";
import { getValidationBlame } from "../LocalOperations";
import { SelectableBlame } from "../../../components/SelectableBlame";

interface ValidationErrorDialogProps extends DialogProps {
  onClose: () => void;
  title?: string;
  text?: string;
  validation: Validation;
  contentStyle?: React.CSSProperties;
}

const _makeTabs = (
  validation: Validation,
  blames?: ValidationBlameInstance[]
): DialogTabs => {
  const tabs: DialogTabs = [];
  if (validation.error) {
    tabs.push({
      title: "Error",
      content: <TitledCodeTextCard value={validation.error} wordWrap />,
    });
  }
  if (validation.warning) {
    tabs.push({
      title: "Warning",
      content: <TitledCodeTextCard value={validation.warning} wordWrap />,
    });
  }
  if (blames && blames.length > 0) {
    tabs.push({
      title: "Blamed instances",
      content: <SelectableBlame blames={blames} />,
    });
  }
  if (getStatusClassname(validation.status) === "success-tag") {
    tabs.push({
      title: "Success",
      content: <Typography>All good, nothing to see here.</Typography>,
    });
  }
  if (tabs.length < 1) {
    tabs.push({
      title: "Info",
      content: (
        <Typography>Sorry, no additional information provided.</Typography>
      ),
    });
  }
  return tabs;
};

const ValidationErrorDialog = (props: ValidationErrorDialogProps) => {
  const {
    onClose,
    open,
    title,
    text,
    validation,
    contentStyle,
    ...dialogProps
  } = props;

  const qBlame = useQuery<ValidationBlameInstance[], BackendError>(
    ["validationBlame", validation.id, validation.length_secs],
    () => getValidationBlame(validation.snapshot_id, validation.id),
    { enabled: open && !!(validation.warning || validation.error) }
  );

  const tabs = open ? _makeTabs(validation, qBlame.data) : [];

  return (
    <TabbedContentDialog
      open={open}
      onClose={onClose}
      prefix={
        text ?? "Providing the details about validation error or warning."
      }
      tabs={tabs}
      title={
        title ??
        `"${
          validation.validator_custom_name || validation.validator_name
        }" details`
      }
      contentStyle={contentStyle}
      maxWidth="md"
      isChoose1stTabOnClose
      {...dialogProps}
    />
  );
};

export default ValidationErrorDialog;
