import { useMemo } from "react";

function toSortedObjStringify(obj: any): string {
  if (Array.isArray(obj)) {
    return JSON.stringify(obj.map(toSortedObjStringify));
  } else if (obj !== null && typeof obj === "object") {
    return JSON.stringify(
      Object.keys(obj)
        .sort() // Sort keys alphabetically
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {} as Record<string, any>)
    );
  }
  return JSON.stringify(obj);
}

function toSortedSnapshotBody(data: any): any {
  try {
    if (Array.isArray(data)) {
      return data
        .map((item) => JSON.parse(toSortedObjStringify(item)))
        .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    }
    return JSON.parse(toSortedObjStringify(data));
  } catch {
    return data;
  }
}

export const useSortedSnapshotBefore = (snapshotData: any) => {
  return useMemo(() => {
    if (!snapshotData) return undefined;
    return JSON.stringify(toSortedSnapshotBody(snapshotData), null, 2);
  }, [snapshotData]);
};
