import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorAlert from "../../../components/ErrorAlert";
import JSONDataDialog from "./JSONDataDialog";
import { useQueryDataFormat } from "../../../hooks/UseQueryDataFormat";
import { useState } from "react";
import { useQueryTemplate } from "../../../hooks/UseQueryTemplate";
import FormatCardTable from "./FormatCardTable";

interface FormatCardProps {
  style?: React.CSSProperties;
  templateId: string;
}

const FormatCard = ({ style, templateId }: FormatCardProps) => {
  const qDataFormat = useQueryDataFormat(templateId);
  const qTemplate = useQueryTemplate(templateId);

  const [isJSONSchemaOpen, setIsJSONSchemaOpen] = useState(false);
  const closeJSONDialog = () => setIsJSONSchemaOpen(false);

  const dataLabels = qDataFormat.data?.data_labels;
  const JSONSchemaString = qDataFormat.data?.json_schema_str;
  const isRenderable = !!dataLabels;

  const formatName =
    qTemplate.isSuccess && !!qTemplate.data.name ? (
      qTemplate.data.name
    ) : (
      <span>
        <i>Unnamed</i> dataset
      </span>
    );

  return (
    <Box style={{ width: "100%" }} borderRadius={1}>
      {qDataFormat.isLoading ? (
        <CircularProgress />
      ) : isRenderable ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Format</Typography>
          </AccordionSummary>
          <Divider variant="middle" />
          <AccordionDetails>
            <Typography variant="body1" color="textSecondary">
              List of required fields:
            </Typography>
            <FormatCardTable templateId={templateId} size="small" />
          </AccordionDetails>
        </Accordion>
      ) : JSONSchemaString ? (
        <>
          <Typography>Schema for this dataset is custom.</Typography>
          <Button
            onClick={() => {
              setIsJSONSchemaOpen(true);
            }}
            variant="contained"
          >
            Show JSON schema
          </Button>
        </>
      ) : (
        <ErrorAlert prefix="Something went wrong. " value={qDataFormat.error} />
      )}
      <JSONDataDialog
        open={isJSONSchemaOpen}
        onClose={closeJSONDialog}
        JSONString={JSONSchemaString}
        title="JSON Schema"
        text="The following JSON schema defines, what requirements must be satisfied for data sample - snapshot."
      />
    </Box>
  );
};

export default FormatCard;
