import { useState } from "react";
import JSONData, { JSONDataProps } from "./JSONData";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { ValidationBlameInstance } from "../types/ValidationBlameInstance";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

interface SelectableBlameProps extends Omit<JSONDataProps, "JSONObject"> {
  blames: ValidationBlameInstance[];
}

export const SelectableBlame = ({ blames, ...props }: SelectableBlameProps) => {
  const [selectedBlame, setSelectedBlame] =
    useState<ValidationBlameInstance | null>(blames[0] ?? null);
  return (
    <FormControl style={{ marginTop: "15px" }}>
      <InputLabel id="select-instance-label">Instance[x]</InputLabel>
      <Select
        labelId="select-instance-label"
        label="Instance[x]"
        value={selectedBlame?.order_number}
        onChange={(e) =>
          setSelectedBlame(
            blames.find((b) => b.order_number === e.target.value) ?? null
          )
        }
        style={{ width: "40%" }}
      >
        {blames.map((blame) => (
          <MenuItem key={blame.order_number} value={blame.order_number}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              {blame.is_current && <StarBorderOutlinedIcon color="warning" />}
              {blame.order_number}
            </Stack>
          </MenuItem>
        ))}
      </Select>
      <JSONData
        JSONObject={selectedBlame?.body}
        wrapperStyle={{ height: "50vh", minWidth: "45vw" }}
        {...props}
      />
    </FormControl>
  );
};
