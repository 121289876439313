import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { BackendError } from "../types/BackendError";
import { Snapshot } from "../types/SnapshotHead";
import { getSnapshot } from "../pages/SnapshotsViewSteps/LocalOperations";

export const useQuerySnapshot = (
  snapshotId: string | number,
  options?: Omit<
    UseQueryOptions<Snapshot, BackendError, Snapshot, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _snapshotId = String(snapshotId);
  return useQuery<Snapshot, BackendError>(
    ["snapshot", _snapshotId],
    () => getSnapshot(_snapshotId),
    options
  );
};
